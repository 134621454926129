<template>
  <section>
    <h2 class="titulo"><strong>Nossos Serviços</strong></h2>
    <ul class="product-list">
      <li class="product-item">
        <div><img loading='lazy' src='@/assets/selecao/img01.png' width='250' height='250' alt="" />
          <div>
            <h3 class="txt-normal"><span><strong>Redes de Proteção para Janelas</strong></span></h3>
            <p>Instalamos redes de proteção em janelas que representam alto risco de acidentes. As crianças, muitas vezes, brincam sozinhas em seus quartos e sem as redes ficam vulneráveis a possíveis acidentes.
            </p>
          </div>
        </div>
      </li>
      <li class="product-item">
        <div><img loading='lazy' src='@/assets/selecao/img02.png' width='250' height='250' alt="" />
          <div>
            <h3 class="txt-normal"><span><strong>Redes de Proteção para Varandas</strong></span></h3>
            <p>Instalamos redes de proteção em varandas e sacadas de apartamentos dando mais segurança e evitando
              acidentes. As varandas possuem grande circulação de pessoas em dia de reunião familiares e eventos.
            </p>
          </div>
        </div>
      </li>
      <li class="product-item">
        <div><img loading='lazy' src='@/assets/selecao/img03.png' width='250' height='250' alt="" />
          <div>
            <h3 class="txt-normal"><span><strong>Redes de Proteção Para Campos de futebol</strong></span></h3>
            <p>Instalamos rede de proteção para campo de futebol é evitar que bolas e outros objetos sejam lançados para
              fora do ambiente, evitando que a bola não seja perdida fora dos limites da campo.
            </p>
          </div>
        </div>
      </li>
      <li class="product-item">
        <div><img loading='lazy' src='@/assets/selecao/img04.png' width='250' height='250' alt="" />
          <div>
            <h3 class="txt-normal"><span><strong>Redes de Proteção Para Quadras poliesportivas</strong></span></h3>
            <p>Instalamos As redes de proteção esportivas são utilizadas principalmente para fins de obstruir a saída de
              bolas em quadras poliesportivas, são ideais para trazer conforto e proteger o ambiente.
            </p>
          </div>
        </div>
      </li>
      <li class="product-item">
        <div><img loading='lazy' src='@/assets/selecao/img05.png' width='250' height='250' alt="" />
          <div>
            <h3 class="txt-normal"><span><strong>Redes de Proteção Para Playgrounds</strong></span></h3>
            <p>Instalamos redes de proteção em playground é instalada no entorno de todo esse espaço, isolando-o do
              ambiente externo e promovendo conforto a criança que brinca com maior liberdade, visando diminuir risco de
              acidentes.
            </p>
          </div>
        </div>
      </li>
      <li class="product-item">
        <div><img loading='lazy' src='@/assets/selecao/img01.png' width='250' height='250' alt="" />
          <div>
            <h3 class="txt-normal"><span><strong>Redes de Proteção Anti-pássaros</strong></span></h3>
            <p>Instalamos redes de proteção contra pássaros impede que as aves entrem em ambientes fechados. A maioria
              deles, principalmente as pombas, gosta de tetos de colégios entre outros.
            </p>
          </div>
        </div>
      </li>
      <li class="product-item">
        <div><img loading='lazy' src='@/assets/selecao/img08.png' width='250' height='250' alt="" />
          <div>
            <h3 class="txt-normal"><span><strong>Redes de Proteção para Animais</strong></span></h3>
            <p>As redes de proteção para animais foi especialmente projetada para proteger animais de estimação contra
              possíveis quedas e acidentes. Ideal para famílias que moram em sobrados e apartamentos com sacadas e
              varandas.
            </p>
          </div>
        </div>
      </li>
      <li class="product-item">
        <div><img loading='lazy' src='@/assets/selecao/img09.png' width='250' height='250' alt="" />
          <div class="bx">
            <h3 class="txt-normal"><span><strong>Redes de Proteção Para Cercamento de piscinas</strong></span></h3>
            <p>Instalamos Cerca de proteção em piscinas. Delimitado a área com uma cerca removível é a melhor maneira de
              proteger suas crianças e seus animais de estimação contra afogamento.
            </p>
          </div>
        </div>
      </li>
      <li class="product-item">
        <div><img loading='lazy' src='@/assets/selecao/img10.png' width='250' height='250' alt="" />
          <div>
            <h3 class="txt-normal"><span><strong>Redes de Proteção para cobertura de piscinas</strong></span></h3>
            <p>Instalamos redes de proteção em piscinas sendo um ítem fundamental para a segurança de piscinas protegendo
              as crianças e animais de estimação contra quedas e acidentes.
            </p>
          </div>
        </div>
      </li>
    </ul>
  </section>
  <section class="cont-selos">
    <p class=""><strong>Nossas redes de proteção são instaladas estritamente em conformidade com as normas de segurança exigidas pela ABNT, proporcionando o máximo de conforto e tranquilidade para você, sua família e seus animais de estimação.</strong></p>
    <div>
      <img src="@/assets/selos.png" alt="conheca" title="conheca" class="responsivel">
    </div>
  </section>
</template>
<style lang='scss' scoped>
/* *{
  margin:1rem 0!important;
 padding: 0!important;
} */
.cont-selos{
  max-width: 90%!important;
  margin:0 auto!important;
}
.product-list {
  img{
      margin-top:0!important;
      max-width:100%!important;
      width: auto;
      height: auto;
  }
  max-width: 90%!important;
  margin:0 auto!important;
  display: grid;
  grid-template-columns: repeat(5,calc((90% - 40px)/5));
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  @media only screen and (max-width: 600px) {
    max-width: 95vw!important;
    grid-template-columns: repeat(2,calc((95vw - 10px)/2))
  }
}
</style>
